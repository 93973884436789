<template>
  <div class="otpVerify-page">
    <layout header="CASA LAPIN" :class="{'blur-page': showAlertError || showSetPin || showConfirmPin}"
      @goback="$router.push('/PhoneVerify')">
      <template v-slot:main>
        <v-row class="body-otpVerify mt-5" no-gutters align-content="start">
          <v-col cols="12" class="text-center py-2 text-header" v-html="$t('otpVerifyTitle')"></v-col>

          <v-col cols="12" class="text-center py-2 text-header">{{$t('otpRefTitle')}} {{otpRef}}</v-col>

          <v-col cols="12" class="text-center py-2 text-header">{{$t('otpVerifyTitle2')}}</v-col>

          <v-col cols="12" class="text-center py-2">
            <v-text-field outlined rounded background-color="white" class="input-text" hide-details dense height="44"
              placeholder="OTP" v-model="otpNo" maxlength="6" @keypress="isNumber()"></v-text-field>
            <small v-if="errorEntry > 0" class="d-block w-100 mt-2" style="color:red;">
              {{$t('invalidOTP')}}
              {{errorEntry}}/3
            </small>
          </v-col>

          <v-col cols="12" class="text-center py-2 mb-5">
            <v-btn rounded large block color="#9D662C" @click="confirmOtp()">{{$t('next')}}</v-btn>
          </v-col>

          <v-col cols="12" class="mt-5 text-center text-header">{{$t('dontOTP')}}</v-col>
          <v-col cols="12" class="text-center text-header">
            <div v-if="timer!=31" style="color:#5671A0;font-size:14px;">
              {{$t('sendAgain')}}
              <span>{{timer}}</span>
            </div>
            <div class="resend-text mb-5" @click="resend()">{{$t('tapForSend')}}</div>
          </v-col>
        </v-row>
      </template>
    </layout>
    <loading v-show="loadingState"></loading>
    <alert type="error" :header="$t('failed')" :title="textErr" v-show="showAlertError" @done="Done"></alert>
    <pin-pad v-show="showSetPin" :backward="false" :header="$t('setPin')" :clear="clearPin" @pinEmit="setPin"></pin-pad>
    <pin-pad v-show="showConfirmPin" :backward="true" :header="$t('verifyPin')" :clear="clearPin" @pinEmit="verifyPin"
      @goback="confirmPinBack"></pin-pad>
  </div>
</template>

<script>
  import Layout from "../../components/layout_2";
  import Loading from "../../components/loading";
  import Alert from "../../components/alert";
  import Pinpad from "../../components/pinPad";
  export default {
    name: "OtpVerify",
    components: {
      layout: Layout,
      loading: Loading,
      alert: Alert,
      "pin-pad": Pinpad,
    },
    data: () => ({
      loadingState: false,
      timer: 31,
      showAlertError: false,
      showSetPin: false,
      showConfirmPin: false,
      textErr: "",
      errorEntry: 0,
      otpNo: "",
      token: null,
      clearPin: false,
    }),
    computed: {
      otpRef() {
        return this.$store.state.moduleAuth.otpRef;
      },
      phoneNo() {
        return this.$store.state.moduleAuth.phoneNo;
      },
    },
    watch: {
      timer(val) {
        if (val < 31 && val != 0) {
          setTimeout(() => {
            this.timer = this.timer - 1;
          }, 1000);
        } else if (val == 0) {
          this.timer = 31;
        }
      },
    },
    methods: {
      async resend() {
        if (this.timer != 31) {
          return false;
        }
        this.loadingState = true;
        let config = await {
          headers: {
            "content-type": "application/json",
            "X-API-KEY": "OTPJFINzM8L6ZCmbIvce1BAFwihvDf3BF",
          },
        };
        let params = await {
          phone_no: this.phoneNo,
        };
        await this.$axios
          .post(
            "https://api.jfin.network/api/v2/paa/auth_phone",
            JSON.stringify(params),
            config
          )
          .then((res) => {
            this.$store.commit("SET_OTPREF", res.data.data.otp_ref);
            this.timer = 30;
          })
          .catch((err) => {});
        this.loadingState = false;
      },
      async confirmOtp() {
        this.loadingState = await true;
        try {
          const access_token = await this.verifyOTP();
          const {
            sessionToken,
            objectId
          } = await this.loginOTP(access_token);
          this.token = sessionToken;
          this.showSetPin = await true;
        } catch (err) {
          this.errorEntry += await 1;
          if (this.errorEntry == 3) {
            await setTimeout(() => {
              this.textErr =
                this.$i18n.locale == "th" ?
                "กรุณาเช็คการเชื่อมต่อ" :
                "Please check connection.";
              this.showAlertError = true;
              this.$router.push("/PhoneVerify");
            }, 1000);
          }
        }
        this.loadingState = await false;
      },
      async verifyOTP() {
        let config = await {
          headers: {
            "content-type": "application/json",
            "X-API-KEY": "OTPJFINzM8L6ZCmbIvce1BAFwihvDf3BF",
          },
        };
        let params = await {
          phone_no: this.phoneNo,
          otp_ref: this.otpRef,
          otp: this.otpNo.replace(/[^0-9\.]+/g, ""),
        };
        let result = await this.$axios.post(
          "https://api.jfin.network/api/v2/paa/verify_otp",
          JSON.stringify(params),
          config
        );
        return result.data.access_token;
      },
      async loginOTP(access_token) {
        let config = await {
          headers: {
            "content-type": "application/json",
          },
        };
        let params = {
          authData: {
            paa: {
              id: this.phoneNo,
              access_token: access_token,
            },
          },
        };
        let result = await this.$axios.post(
          "/login",
          JSON.stringify(params),
          config
        );
        let sessionToken = result.data.sessionToken;
        let objectId = result.data.objectId;
        return {
          sessionToken,
          objectId,
        };
      },
      isNumber(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      Done() {
        this.showAlertError = false;
      },
      errAlert(msg) {
        this.clearPin = !this.clearPin;
        this.textErr = msg;
        this.showAlertError = true;
      },
      async setPin(val) {
        this.loadingState = true;
        try {
          let config = {
            headers: {
              'x-parse-session-token': this.token
            }
          };
          let params = {
            pin: val,
          };
          let response = await this.$axios.post(
            "/set_new_pin",
            params,
            config
          );
          let status = response.data.status;
          if (status == 200) {
            this.showSetPin = false;
            this.clearPin = !this.clearPin;
            setTimeout(() => {
              this.showConfirmPin = true;
            }, 200);
          } else {
            this.errAlert(
              this.$i18n.locale == "th" ?
              "ตั้ง PIN ไม่สำเร็จ <br> กรุณาลองใหม่อีกครั้ง" :
              "Set Pin failed <br> Please try again."
            );
          }
        } catch (err) {
          this.errAlert(
            this.$i18n.locale == "th" ?
            "กรุณาเช็คการเชื่อมต่อ" :
            "Please check connection."
          );
        }
        this.loadingState = false;
      },
      async verifyPin(val) {
        this.loadingState = true;
        try {
          let config = {
            headers: {
              'x-parse-session-token': this.token
            }
          };
          let params = {
            pin: val,
          };
          let response = await this.$axios.post(
            "/verify_pin",
            params,
            config
          );
          let verifyPin = response.data;
          if (verifyPin.status == 200) {
            localStorage.setItem("sessionToken_casalapin", this.token);
            localStorage.setItem("pinReq_casalapin", true);
            localStorage.setItem("notification_casalapin", true);
            await this.$store.dispatch("getEkycData");
            await this.$store.dispatch('getMe');
            await this.$router.push("/");
            this.showConfirmPin = await false;
            this.clearPin = !this.clearPin;
          } else if (verifyPin.status == 400) {
            if (verifyPin.lock <= 0) {
              this.errAlert(
                this.$i18n.locale == "th" ?
                `รหัสผ่านไม่ถูกต้อง<br>attemped ${verifyPin.attempted}/3` :
                `Password has invalid<br>attemped ${verifyPin.attempted}/3`
              );
            } else {
              this.errAlert(
                this.$i18n.locale == "th" ?
                `กรุณาลองใหม่อีกครั้ง<br> ภายในอีก ${verifyPin.lock} วินาที` :
                `Please try again<br> in ${verifyPin.lock} sec.`
              );
            }
          } else {
            this.errAlert(
              this.$i18n.locale == "th" ?
              "ไม่พบรหัสผ่าน <br> กรุณา OTP ใหม่อีกครั้ง" :
              "Password notfound <br> Please OTP again"
            );
          }
        } catch (err) {
          console.log(err);
          this.errAlert(
            this.$i18n.locale == "th" ?
            "กรุณาเช็คการเชื่อมต่อ" :
            "Please check connection."
          );
        }
        this.loadingState = false;
      },
      confirmPinBack() {
        this.showConfirmPin = false;
        setTimeout(() => {
          this.showSetPin = true;
        }, 200);
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/color.scss";

  .otpVerify-page {
    height: 100%;

    .body-otpVerify {
      max-width: 420px;
      padding: 0px 32px;

      .text-header {
        font-size: 14px;
        font-weight: 800;
      }

      .resend-text {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
</style>