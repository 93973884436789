<template>
  <v-container class="layout-2 pa-0" fluid>
    <v-row justify="start" align-content="space-around" class="layout-header" no-gutters>
     <v-col cols="2" class="pl-1">
        <div v-ripple="{ class: 'riple' }" class="pa-1 white--text d-flex align-center" style="height:auto;width:3em;" @click="$emit('goback')">
          <img src="/Image/back@2x.png" style="height:auto;width:100%;" />
        </div>
      </v-col>
      <v-col cols="12" align-self="center" class="header mb-2">{{header}}</v-col>
    </v-row>
    <v-row class="layout-body" no-gutters align-content="start">
      <v-col cols="12" class="text-center d-flex justify-center">
        <slot name="main"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Layout2",
  props: {
    header: {
      type: String,
      default: "",
    },
    backward: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";

.layout-2 {
  padding: 0!important;
  height: 100%;

  .layout-header {
    background-image: url("/Image/BG_2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: calc(100vh * 0.2);
    min-height: 140px;

    .header {
      color: white;
      font-weight: 800;
      font-size: 1.8em;
      text-align: center;
    }
  }

  .layout-body {
    height: auto;
    min-height: calc(100vh * 0.8);
    background-image: url("/Image/BG_4.png");
    background-size: contain;
    background-repeat: repeat;
  }
}
</style>